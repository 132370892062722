import React, { useEffect, useState, useRef } from "react";
import NavbarOne from '../siteTheme/NavbarOne';
import Config from "../../core/config";
import { makeRequest } from "../../core/services/v1/request";
import { toast } from "../../core/lib/toastAlert";
import Modal from 'react-bootstrap/Modal';
import line from "../../assets/images/bg_coin_line.jpg";
export default function Convert(props) {
    let myTimeout = useRef();

    const [currencyModel, setCurrencyModel] = useState({ show: false, type: "from", list: [], filterList: [] });
    const [requestFrom, setRequestFrom] = useState({
        "fromCoin": "",
        "toCoin": "",
        "requestAmount": 0,
        "requestCoin": "",
        "orderType": "requestQuote" // requestQuote | market
    });

    const [convResp, setConvResp] = useState({});
    const [searchCoinText, setSearchCoinText] = useState("");
    const [curSwap, setCurSwap] = useState({
        from: "from", to: "to"
    })

    const [fromCurrencyList, setFromCurrencyList] = useState([]);
    const [toCurrencyList, setToCurrencyList] = useState([]);
    const [selConvPair, setSelConvPair] = useState({});
    const [currencyBal, setcurrencyBal] = useState(0);

    const [error_msg, seterror_msg] = useState({ "balance": "" });

    useEffect(() => {
        if (curSwap && curSwap.to && selConvPair && selConvPair[curSwap.to + "Currency"]) {
            setRequestFrom((prevState) => ({
                ...prevState,
                requestCoin: selConvPair[curSwap.from + "Currency"].currencySymbol
            }));
            currencyBalance(selConvPair[curSwap.from + "Currency"].currencyId);
        }
    }, [curSwap]);

    useEffect(() => {
        if (requestFrom && requestFrom.requestCoin && requestFrom.requestAmount != undefined) {
            submitOrRequestSwap();
        }
    }, [requestFrom]);

    useEffect(() => {
        getconvertPair();
        currencyBalance();
    }, []);

    async function getconvertPair() {
        try {
            const params = {
                url: `${Config.V2_API_URL}convert/pair/list`,
                method: 'GET',
            }
            const response = await makeRequest(params);
            if (response.status) {
                if (response.list.length > 0) {
                    setRequestFrom({
                        "fromCoin": response.list[0].fromCurrency.currencySymbol,
                        "toCoin": response.list[0].toCurrency.currencySymbol,
                        // "requestAmount": 0,
                        "requestCoin": response.list[0].fromCurrency.currencySymbol,
                        "orderType": "requestQuote"
                    })
                    setFromCurrencyList(response.list);
                    setToCurrencyList(response.list);
                    setSelConvPair(response.list[0]);
                    currencyBalance(response.list[0].fromCurrency.currencyId);
                }
            }
        } catch (err) { }
    }
    async function currencyBalance(currencyId) {
        try {
            if(currencyId) {
                const params = {
                    url: `${Config.V1_API_URL}wallet/getCurrencyBalance`,
                    method: 'POST',
                    body: { currencyId: currencyId }
                }
                const response = await makeRequest(params);
                if (response.status && response.data) {
                    let balance = (response.data.amount) ? response.data.amount : 0;
                    setcurrencyBal(balance);
                }
            }
        } catch (err) { }
    }

    const amountChange = (event) => {
        setRequestFrom(prevState => ({
            ...prevState,
            requestAmount: event.target.value
        }));
        setTimeout(function () {
            myTimeout.current = submitOrRequestSwap("requestQuote", event.target.value);
        }, 1000);
        return () => clearTimeout(myTimeout.current);
    }
    // async function selectPair(val,type){
    //     try {
    //         setSelConvPair(val);
    //         let passData = { 
    //             currencyId : val[type+"Currency"].currencyId,
    //             from : type
    //         }
    //         const params = { 
    //             url: `${Config.V2_API_URL}convert/pair/list/select`,
    //             method: 'POST',
    //             body: passData
    //         }
    //         setCurrencyModel({
    //             show: false, type: "", list: [], filterList: []
    //         });
    //         const response = await makeRequest(params);
    //         const responseList = response.list ? response.list : [];
    //         if(type === "from") {
    //             setToCurrencyList(responseList);
    //         } else {
    //             setFromCurrencyList(responseList);
    //         }
    //     } catch(err){}
    // }
    async function selectPairList(val, type) {
        if (type === "from") {
            const filterVal = await fromCurrencyList.filter(currency => currency.fromCurrencyId === val.fromCurrencyId);
            setToCurrencyList(filterVal);
        }
        else {
            const filterVal = await toCurrencyList.filter(currency => currency.toCurrencyId === val.toCurrencyId);
            setFromCurrencyList(filterVal);
        }
        setSelConvPair(val);
        setCurrencyModel({
            show: false, type: "", list: [], filterList: []
        });
    }
    async function submitOrRequestSwap(type = "requestQuote", amount) {
        try {
            if (requestFrom.requestCoin) {
                let reqData = {
                    "fromCoin": requestFrom.fromCoin,
                    "toCoin": requestFrom.toCoin,
                    "requestAmount": amount !== undefined ? amount : requestFrom.requestAmount,
                    "requestCoin": requestFrom.requestCoin,
                    "orderType": type
                }
                const params = {
                    url: `${Config.V2_API_URL}convert/request`,
                    method: 'POST',
                    body: reqData
                }
                const response = await makeRequest(params);
                const {
                    message: respMessage = "",
                    data: respData = "",
                    status: respStatus = false
                } = response;
                const errorType = respStatus ? "success" : "error";
                if (type === "requestQuote") {
                    if (response.status) {
                        setConvResp(respData);
                    }
                    else {
                        setConvResp({});
                    }
                    seterror_msg({ "balance": respMessage });
                }
                if (type === "market") {
                    if (response.status) {
                        currencyBalance(selConvPair[curSwap.from + "Currency"].currencyId);
                        toast({ errorType, message: respMessage });
                    }
                    else {
                        toast({ errorType, message: respMessage });
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }
    }
    async function handleClose() {
        setCurrencyModel({
            show: false, type: "", list: [], filterList: []
        });
    }
    return (
        <div>
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
                pageName="Convert"
            />
            <div className="under-line-effect">
                <img src={line} alt="bg_coin_line" />
            </div>
            <div className="earn-banner-container">
                <div className="row earn-banner-inside mx-auto">
                    <div className="col-lg-12">
                        <div className="earn-header">{Config.SITENAME}</div>
                        <div className="earn-content">Supports the conversion of Fiat and Crypto assets.</div>
                    </div>
                </div>
            </div>
            {selConvPair && selConvPair.fromCurrency && selConvPair.toCurrency ?
            <div className="row justify-content-center">
                <div className="col-lg-5 convert-main-container">
                    <div className="row convet-container">
                        <div className="col-lg-12">
                            <div className="row mb-4">
                                <div className="col-lg-12">
                                    <span className="convert-header">Convert</span>
                                </div>
                            </div>
                            <div className="row from-container mb-3">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-4 from-label">
                                            <span>From</span>
                                        </div>
                                        <div className="col-lg-8 from-tranfer-container">
                                            <span>Available:
                                                <span>
                                                    {currencyBal.toFixed(convResp.convCurrencyDet ? convResp.convCurrencyDet.siteDecimal : 2) + " "}
                                                    {selConvPair[curSwap.from + "Currency"].currencySymbol}
                                                </span>
                                                {/* <span className="deposit-transfer">Deposit Transfer</span> */}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mt-3 from-img-container">
                                        <div className="col-lg-1 col-2 coin-price-container">
                                            <span>
                                                <img src={selConvPair[curSwap.from + "Currency"].image} className="convert-icon" />
                                            </span>
                                        </div>
                                        <div className="col-3">
                                            <div>
                                                {selConvPair[curSwap.from + "Currency"].currencySymbol}
                                                <span className="convert-popup-image dropdown-toggle" onClick={() => { setCurrencyModel({ show: true, type: curSwap.from, list: curSwap.from === "from" ? fromCurrencyList : toCurrencyList, filterList: curSwap.from ? toCurrencyList : fromCurrencyList }); }}>
                                                    {/* &#11205; */}
                                                </span>
                                            </div>
                                            <div className="convert-small-label mt-2">{selConvPair[curSwap.from + "Currency"].currencyName}</div>
                                        </div>
                                        <div className="col-lg-8 col-7 coin-price-container">
                                            <span className="convert-small-label swap-data">
                                                <input placeholder="Enter Amount" className="coin-count" value={requestFrom.requestAmount ? requestFrom.requestAmount : ""} onChange={amountChange} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {error_msg && <p className="error_msg">{error_msg.balance} </p>}

                            <div className="row arrow-main-container">
                                <div className="col-1 arrow-container">
                                    <div className="arrow-icon" onClick={() => {
                                        setCurSwap({
                                            from: curSwap.from === "from" ? "to" : "from",
                                            to: curSwap.to === "from" ? "to" : "from"
                                        });
                                    }}>
                                        <span className="arrow-icon-span">&#10606;</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row from-container mb-3">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-4 from-label">
                                            <span>To</span>
                                        </div>
                                        <div className="col-lg-8 from-tranfer-container">
                                            {/* <span>Available: <span>0 BTC</span> <span className="deposit-transfer">Deposit Transfer</span></span> */}
                                        </div>
                                    </div>
                                    <div className="row mt-3 from-img-container">
                                        <div className="col-lg-1 col-2 coin-price-container">
                                            <span>
                                                <img src={selConvPair[curSwap.to + "Currency"].image} className="convert-icon" alt="img" />
                                            </span>
                                        </div>
                                        <div className="col-3">
                                            <div>
                                                {selConvPair[curSwap.to + "Currency"].currencySymbol}
                                                <span className="convert-popup-image dropdown-toggle" onClick={() => { setCurrencyModel({ show: true, type: curSwap.to, list: curSwap.from === "from" ? fromCurrencyList : toCurrencyList, filterList: curSwap.from === "from" ? fromCurrencyList : toCurrencyList }); }}>
                                                    {/* &#11205; */}
                                                </span>
                                            </div>
                                            <div className="convert-small-label mt-2">{selConvPair[(curSwap.to ? "from" : "to") + "Currency"].currencyName}</div>
                                        </div>
                                        {convResp.convertRecAmount > 0 &&
                                            <div className="col-lg-8 col-7 coin-price-container">
                                                <span className="convert-small-label swap-data">{convResp.convertRecAmount.toFixed(convResp.convCurrencyDet ? convResp.convCurrencyDet.siteDecimal : 2)}</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 p-0">
                                    <div className="line"></div>
                                </div>
                                <div className="col-lg-4 coin-to-coin">
                                    {curSwap.from === "from" ? <span>
                                        1 {" "}
                                        {selConvPair["fromCurrency"].currencySymbol + " "}
                                        ≈
                                        {" "}
                                        {(1 * selConvPair.sellPrice).toFixed(selConvPair.priceDecimal)} {" "}
                                        {selConvPair["toCurrency"].currencySymbol}
                                    </span> :
                                        <span>
                                            1 {" "}
                                            {selConvPair["toCurrency"].currencySymbol + " "}
                                            ≈
                                            {(1 / selConvPair.buyPrice).toFixed(selConvPair.priceDecimal)} {" "}
                                            {selConvPair["fromCurrency"].currencySymbol}
                                        </span>}
                                </div>
                                <div className="col-lg-4 p-0">
                                    <div className="line"></div>
                                </div>
                            </div>
                            {convResp.feeAmt > 0 ?
                                <div className="row mt-2">
                                    <div className="col-lg-8 from-label">
                                        <span>Quote Price</span>
                                    </div>
                                    <div className="col-lg-4 convert-price-container">
                                        <span className="receive-price">
                                            {curSwap.from === "from" ? selConvPair.sellPrice : selConvPair.buyPrice} {" "}
                                            {selConvPair["toCurrency"].currencySymbol}
                                        </span>
                                    </div>
                                </div> : ""}
                            {convResp.feeAmt > 0 ?
                                <div className="row mt-2">
                                    <div className="col-lg-8 from-label">
                                        <span>Fee</span>
                                    </div>
                                    <div className="col-lg-4 convert-price-container">
                                        <span className="receive-price">
                                            {convResp.feeAmt.toFixed(convResp.convCurrencyDet.siteDecimal) + " "}
                                        </span>
                                    </div>
                                </div> : ""}
                            {convResp.gstAmt > 0 ?
                                <div className="row">
                                    <div className="col-lg-8 from-label">
                                        <span>GST Fees</span>
                                    </div>
                                    <div className="col-lg-4 convert-price-container">
                                        <span className=""> {convResp.gstAmt.toFixed(convResp.convCurrencyDet.siteDecimal)} Fee</span>
                                    </div>
                                </div> : ""}
                            {convResp.TDSAmt > 0 ?
                                <div className="row">
                                    <div className="col-lg-8 from-label">
                                        <span>TDS Fees</span>
                                    </div>
                                    <div className="col-lg-4 convert-price-container">
                                        <span className=""> {convResp.TDSAmt.toFixed(convResp.convCurrencyDet.siteDecimal)} Fee</span>
                                    </div>
                                </div> : ""}
                            {/* {convResp.totalFeeAmt > 0 ?
                        <div className="row">
                            <div className="col-lg-8 from-label">
                                <span>Total Fees</span>
                            </div>
                            <div className="col-lg-4 convert-price-container">
                                <span className="grid-chip-success"> {convResp.totalFeeAmt.toFixed(convResp.convCurrencyDet.siteDecimal)} Fee</span>
                            </div>
                        </div>:""} */}
                            {/* {(convResp.convertRecAmount > 0 && convResp.convCurrencyDet) ? 
                        <div className="row mt-2">
                            <div className="col-8 from-label">
                                <span>Receivables</span>
                            </div>
                            <div className="col-lg-4 convert-price-container">
                                <span className="receive-price"> 
                                { convResp.convertRecAmount.toFixed(convResp.convCurrencyDet.siteDecimal)+" " }
                                { convResp.convCurrencyDet.currencySymbolCode}
                                </span>
                            </div>
                        </div>: ""} */}
                            <div className="row mt-4 justify-content-center">
                                <div className="col-lg-4 convert-btn" onClick={() => submitOrRequestSwap("market")}>
                                    <span>Swap</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : ""}
            <Modal show={currencyModel.show === true} onHide={() => {
                handleClose();
                setSearchCoinText("");
            }}>
                <Modal.Body> <div className="row dropdown-container">
                    <div className="row mb-3">
                        <div className="col-11 p-0">
                            <span>Select Currency</span>
                        </div>
                        <div className="col-1 p-0 coin-popup-close">
                            <div onClick={() => {
                                handleClose();
                                setSearchCoinText("");
                            }}>X</div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row justify-content-center popup-search">
                            <div className="col-12 p-0">
                                <input placeholder="Search" className="search-text" value={searchCoinText}
                                    onChange={e => {
                                        let searchText = e.target.value?.trim().toLowerCase();
                                        setSearchCoinText(searchText);
                                        var filterList = [];
                                        currencyModel.list.forEach((val, key) => {
                                            if (
                                                (currencyModel.type === "from" && val.fromCurrency.currencyName.toLowerCase().search(searchText) !== -1) ||
                                                (currencyModel.type === "to" && val.toCurrency.currencyName.toLowerCase().search(searchText) !== -1)
                                            ) {
                                                filterList.push(val);
                                            }
                                        });
                                        setCurrencyModel((prevState) => ({
                                            ...prevState,
                                            filterList: filterList
                                        }));
                                    }}
                                />
                            </div>
                        </div>
                        <hr></hr>
                        <div className="search-dropdown-container">
                            {
                                currencyModel.filterList.map((val, key) => {
                                    const data = val[currencyModel.type + "Currency"];
                                    return (
                                        <div
                                            className="row justify-content-center convert-list"
                                            onClick={() => selectPairList(val, currencyModel.type)}
                                        >
                                            <div className="col-2 coin-price-container">
                                                <img src={data.image} className="convert-icon-popup" alt="icon" />
                                            </div>
                                            <div className="col-10">
                                                <div>{data.currencySymbol}</div>
                                                <div className="convert-small-label mt-2">{data.currencyName}</div>
                                            </div>
                                            {/* <div className="col-1"> */}
                                            {/* <div>{data.count}</div>
                                                <div className="convert-small-label mt-2">{data.currency}{val.price}</div> */}
                                            {/* </div> */}
                                        </div>
                                    )
                                })
                            }
                            {
                                currencyModel.filterList.length === 0 && <div className="row justify-content-start convert-list">
                                    <div className="col--1"></div>  <div className="col-5">Data not matched</div>
                                </div>
                            }
                        </div>
                    </div>
                </div></Modal.Body>
            </Modal>
        </div>
    );
}
